import React from "react";
import "../css/style.css";
import "../css/bootstrap.min.css";
import AddressTopTab from "../Component/AddressTopTab";
import NavBar from "../Component/NavBar";
import Productlisting from "../Component/Productlisting";
import Footer from "../Component/Footer";

const Home = () => {
  return (
    <div>
      <meta charSet="utf-8" />
      <title>PET SHOP - Pet Shop Website Template</title>
      <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      <meta content="Free HTML Templates" name="keywords" />
      <meta content="Free HTML Templates" name="description" />
      {/* Favicon */}
      <link href="img/favicon.ico" rel="icon" />
      {/* Google Web Fonts */}
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins&family=Roboto:wght@700&display=swap"
        rel="stylesheet"
      />
      {/* Icon Font Stylesheet */}
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css"
        rel="stylesheet"
      />
      <link href="lib/flaticon/font/flaticon.css" rel="stylesheet" />
      {/* Libraries Stylesheet */}
      <link
        href="lib/owlcarousel/assets/owl.carousel.min.css"
        rel="stylesheet"
      />
      {/* Customized Bootstrap Stylesheet */}
      <link href="css/bootstrap.min.css" rel="stylesheet" />
      {/* Template Stylesheet */}
      <link href="css/style.css" rel="stylesheet" />
      {/* Topbar Start */}
      <AddressTopTab />
      {/* Topbar End */}
      {/* Navbar Start */}
      <NavBar />
      {/* Navbar End */}
      {/* Hero Start */}
      <div className="container-fluid bg-primary py-5 mb-5 hero-header">
        <div className="container py-5">
          <div className="row justify-content-start">
            <div className="col-lg-8 text-center text-lg-start">
              <h1
                className="display-1   mb-lg-4"
                style={{ fontSize: "30px", fontFamily: "sans-serif",color:"white" }}
              >
                GREENMEDIBIOTECH
              </h1>
              <h1 className="text-uppercase text-white mb-lg-4">
                Make your Pets Feel Special
              </h1>
              <p className="fs-4 text-white mb-lg-4">
                "Our perfect companions never have fewer than four feet"
              </p>
              {/* <div class="d-flex align-items-center justify-content-center justify-content-lg-start pt-5">
                          <a href="" class="btn btn-outline-light border-2 py-md-3 px-md-5 me-5">Read More</a>
                          <button type="button" class="btn-play" data-bs-toggle="modal"
                              data-src="https://www.youtube.com/embed/DWRcNpR6Kdc" data-bs-target="#videoModal">
                              <span></span>
                          </button>
                          <h5 class="font-weight-normal text-white m-0 ms-4 d-none d-sm-block">Play Video</h5>
                      </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* Hero End */}
      {/* Video Modal Start */}
      <div
        className="modal fade"
        id="videoModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Youtube Video
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              {/* 16:9 aspect ratio */}
              <div className="ratio ratio-16x9">
                <iframe
                  className="embed-responsive-item"
                  src
                  id="video"
                  allowFullScreen
                  allowscriptaccess="always"
                  allow="autoplay"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Video Modal End */}
      {/* About Start */}
      {/* <div class="container-fluid py-5">
          <div class="container">
              <div class="row gx-5">
                  <div class="col-lg-5 mb-5 mb-lg-0" style="min-height: 500px;">
                      <div class="position-relative h-100">
                          <img class="position-absolute w-100 h-100 rounded" src="img/about.jpg" style="object-fit: cover;">
                      </div>
                  </div>
                  <div class="col-lg-7">
                      <div class="border-start border-5 border-primary ps-5 mb-5">
                          <h6 class="text-primary text-uppercase">About Us</h6>
                          <h1 class="display-5 text-uppercase mb-0">We Keep Your Pets Happy All Time</h1>
                      </div>
                      <h4 class="text-body mb-4">Diam dolor diam ipsum tempor sit. Clita erat ipsum et lorem stet no labore lorem sit clita duo justo magna dolore</h4>
                      <div class="bg-light p-4">
                          <ul class="nav nav-pills justify-content-between mb-3" id="pills-tab" role="tablist">
                              <li class="nav-item w-50" role="presentation">
                                  <button class="nav-link text-uppercase w-100 active" id="pills-1-tab" data-bs-toggle="pill"
                                      data-bs-target="#pills-1" type="button" role="tab" aria-controls="pills-1"
                                      aria-selected="true">Our Mission</button>
                              </li>
                              <li class="nav-item w-50" role="presentation">
                                  <button class="nav-link text-uppercase w-100" id="pills-2-tab" data-bs-toggle="pill"
                                      data-bs-target="#pills-2" type="button" role="tab" aria-controls="pills-2"
                                      aria-selected="false">Our Vission</button>
                              </li>
                          </ul>
                          <div class="tab-content" id="pills-tabContent">
                              <div class="tab-pane fade show active" id="pills-1" role="tabpanel" aria-labelledby="pills-1-tab">
                                  <p class="mb-0">Tempor erat elitr at rebum at at clita aliquyam consetetur. Diam dolor diam ipsum et, tempor voluptua sit consetetur sit. Aliquyam diam amet diam et eos sadipscing labore. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit. Sanctus clita duo justo et tempor consetetur takimata eirmod, dolores takimata consetetur invidunt magna dolores aliquyam dolores dolore. Amet erat amet et magna</p>
                              </div>
                              <div class="tab-pane fade" id="pills-2" role="tabpanel" aria-labelledby="pills-2-tab">
                                  <p class="mb-0">Tempor erat elitr at rebum at at clita aliquyam consetetur. Diam dolor diam ipsum et, tempor voluptua sit consetetur sit. Aliquyam diam amet diam et eos sadipscing labore. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit. Sanctus clita duo justo et tempor consetetur takimata eirmod, dolores takimata consetetur invidunt magna dolores aliquyam dolores dolore. Amet erat amet et magna</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div> */}
      {/* About End */}
      {/* Services Start */}
      {/* <div class="container-fluid py-5">
          <div class="container">
              <div class="border-start border-5 border-primary ps-5 mb-5" style="max-width: 600px;">
                  <h6 class="text-primary text-uppercase">Services</h6>
                  <h1 class="display-5 text-uppercase mb-0">Our Excellent Pet Care Services</h1>
              </div>
              <div class="row g-5">
                  <div class="col-md-6">
                      <div class="service-item bg-light d-flex p-4">
                          <i class="flaticon-house display-1 text-primary me-4"></i>
                          <div>
                              <h5 class="text-uppercase mb-3">Pet Boarding</h5>
                              <p>Kasd dolor no lorem sit tempor at justo rebum rebum stet justo elitr dolor amet sit</p>
                              <a class="text-primary text-uppercase" href="">Read More<i class="bi bi-chevron-right"></i></a>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="service-item bg-light d-flex p-4">
                          <i class="flaticon-food display-1 text-primary me-4"></i>
                          <div>
                              <h5 class="text-uppercase mb-3">Pet Feeding</h5>
                              <p>Kasd dolor no lorem sit tempor at justo rebum rebum stet justo elitr dolor amet sit</p>
                              <a class="text-primary text-uppercase" href="">Read More<i class="bi bi-chevron-right"></i></a>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="service-item bg-light d-flex p-4">
                          <i class="flaticon-grooming display-1 text-primary me-4"></i>
                          <div>
                              <h5 class="text-uppercase mb-3">Pet Grooming</h5>
                              <p>Kasd dolor no lorem sit tempor at justo rebum rebum stet justo elitr dolor amet sit</p>
                              <a class="text-primary text-uppercase" href="">Read More<i class="bi bi-chevron-right"></i></a>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="service-item bg-light d-flex p-4">
                          <i class="flaticon-cat display-1 text-primary me-4"></i>
                          <div>
                              <h5 class="text-uppercase mb-3">Pet Training</h5>
                              <p>Kasd dolor no lorem sit tempor at justo rebum rebum stet justo elitr dolor amet sit</p>
                              <a class="text-primary text-uppercase" href="">Read More<i class="bi bi-chevron-right"></i></a>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="service-item bg-light d-flex p-4">
                          <i class="flaticon-dog display-1 text-primary me-4"></i>
                          <div>
                              <h5 class="text-uppercase mb-3">Pet Exercise</h5>
                              <p>Kasd dolor no lorem sit tempor at justo rebum rebum stet justo elitr dolor amet sit</p>
                              <a class="text-primary text-uppercase" href="">Read More<i class="bi bi-chevron-right"></i></a>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="service-item bg-light d-flex p-4">
                          <i class="flaticon-vaccine display-1 text-primary me-4"></i>
                          <div>
                              <h5 class="text-uppercase mb-3">Pet Treatment</h5>
                              <p>Kasd dolor no lorem sit tempor at justo rebum rebum stet justo elitr dolor amet sit</p>
                              <a class="text-primary text-uppercase" href="">Read More<i class="bi bi-chevron-right"></i></a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div> */}
      {/* Services End */}
      {/* Products Start */}
      <Productlisting />
      {/* Products End */}
      {/* Offer Start */}
      <div className="container-fluid bg-offer my-5 py-5">
        <div className="container py-5">
          <div className="row gx-5 justify-content-start">
            <div className="col-lg-7">
              <div className="border-start border-5 border-dark ps-5 mb-5">
                <h6 className="text-dark text-uppercase">Special Offer</h6>
                <h1 className="display-5 text-uppercase text-white mb-0">
                  Save 50% on all items your first order
                </h1>
              </div>
              <p className="text-white mb-4">
                We provide Pet Parents with a convenient and reliable source for
                pet supplies and services.We offer a different kind of shopping
                Experience for your furry partner. We help you shop according to
                what matters to you. We are a great source of local pet
                knowledge.
              </p>
              <a href="/product" className="btn btn-light py-md-3 px-md-5 me-3">
                Shop Now
              </a>
              <a href className="btn btn-outline-light py-md-3 px-md-5">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Offer End */}
      {/* Pricing Plan Start */}
      {/* <div class="container-fluid py-5">
          <div class="container">
              <div class="border-start border-5 border-primary ps-5 mb-5" style="max-width: 600px;">
                  <h6 class="text-primary text-uppercase">Pricing Plan</h6>
                  <h1 class="display-5 text-uppercase mb-0">Competitive Pricing For Pet Services</h1>
              </div>
              <div class="row g-5">
                  <div class="col-lg-4">
                      <div class="bg-light text-center pt-5 mt-lg-5">
                          <h2 class="text-uppercase">Basic</h2>
                          <h6 class="text-body mb-5">The Best Choice</h6>
                          <div class="text-center bg-primary p-4 mb-2">
                              <h1 class="display-4 text-white mb-0">
                                  <small class="align-top"
                                      style="font-size: 22px; line-height: 45px;">$</small>49<small
                                      class="align-bottom" style="font-size: 16px; line-height: 40px;">/
                                      Mo</small>
                              </h1>
                          </div>
                          <div class="text-center p-4">
                              <div class="d-flex align-items-center justify-content-between mb-1">
                                  <span>HTML5 & CSS3</span>
                                  <i class="bi bi-check2 fs-4 text-primary"></i>
                              </div>
                              <div class="d-flex align-items-center justify-content-between mb-1">
                                  <span>Bootstrap v5</span>
                                  <i class="bi bi-check2 fs-4 text-primary"></i>
                              </div>
                              <div class="d-flex align-items-center justify-content-between mb-1">
                                  <span>Responsive Layout</span>
                                  <i class="bi bi-x fs-4 text-danger"></i>
                              </div>
                              <div class="d-flex align-items-center justify-content-between mb-1">
                                  <span>Browsers Compatibility</span>
                                  <i class="bi bi-x fs-4 text-danger"></i>
                              </div>
                              <a href="" class="btn btn-primary text-uppercase py-2 px-4 my-3">Order Now</a>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-4">
                      <div class="bg-light text-center pt-5">
                          <h2 class="text-uppercase">Standard</h2>
                          <h6 class="text-body mb-5">The Best Choice</h6>
                          <div class="text-center bg-dark p-4 mb-2">
                              <h1 class="display-4 text-white mb-0">
                                  <small class="align-top"
                                      style="font-size: 22px; line-height: 45px;">$</small>99<small
                                      class="align-bottom" style="font-size: 16px; line-height: 40px;">/
                                      Mo</small>
                              </h1>
                          </div>
                          <div class="text-center p-4">
                              <div class="d-flex align-items-center justify-content-between mb-1">
                                  <span>HTML5 & CSS3</span>
                                  <i class="bi bi-check2 fs-4 text-primary"></i>
                              </div>
                              <div class="d-flex align-items-center justify-content-between mb-1">
                                  <span>Bootstrap v5</span>
                                  <i class="bi bi-check2 fs-4 text-primary"></i>
                              </div>
                              <div class="d-flex align-items-center justify-content-between mb-1">
                                  <span>Responsive Layout</span>
                                  <i class="bi bi-check2 fs-4 text-primary"></i>
                              </div>
                              <div class="d-flex align-items-center justify-content-between mb-1">
                                  <span>Browsers Compatibility</span>
                                  <i class="bi bi-x fs-4 text-danger"></i>
                              </div>
                              <a href="" class="btn btn-primary text-uppercase py-2 px-4 my-3">Order Now</a>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-4">
                      <div class="bg-light text-center pt-5 mt-lg-5">
                          <h2 class="text-uppercase">Extended</h2>
                          <h6 class="text-body mb-5">The Best Choice</h6>
                          <div class="text-center bg-primary p-4 mb-2">
                              <h1 class="display-4 text-white mb-0">
                                  <small class="align-top"
                                      style="font-size: 22px; line-height: 45px;">$</small>149<small
                                      class="align-bottom" style="font-size: 16px; line-height: 40px;">/
                                      Mo</small>
                              </h1>
                          </div>
                          <div class="text-center p-4">
                              <div class="d-flex align-items-center justify-content-between mb-1">
                                  <span>HTML5 & CSS3</span>
                                  <i class="bi bi-check2 fs-4 text-primary"></i>
                              </div>
                              <div class="d-flex align-items-center justify-content-between mb-1">
                                  <span>Bootstrap v5</span>
                                  <i class="bi bi-check2 fs-4 text-primary"></i>
                              </div>
                              <div class="d-flex align-items-center justify-content-between mb-1">
                                  <span>Responsive Layout</span>
                                  <i class="bi bi-check2 fs-4 text-primary"></i>
                              </div>
                              <div class="d-flex align-items-center justify-content-between mb-1">
                                  <span>Browsers Compatibility</span>
                                  <i class="bi bi-check2 fs-4 text-primary"></i>
                              </div>
                              <a href="" class="btn btn-primary text-uppercase py-2 px-4 my-3">Order Now</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div> */}
      {/* Pricing Plan End */}
      {/* Team Start */}
      {/* <div class="container-fluid py-5">
          <div class="container">
              <div class="border-start border-5 border-primary ps-5 mb-5" style="max-width: 600px;">
                  <h6 class="text-primary text-uppercase">Team Members</h6>
                  <h1 class="display-5 text-uppercase mb-0">Qualified Pets Care Professionals</h1>
              </div>
              <div class="owl-carousel team-carousel position-relative" style="padding-right: 25px;">
                  <div class="team-item">
                      <div class="position-relative overflow-hidden">
                          <img class="img-fluid w-100" src="img/team-1.jpg" alt="">
                          <div class="team-overlay">
                              <div class="d-flex align-items-center justify-content-start">
                                  <a class="btn btn-light btn-square mx-1" href="#"><i class="bi bi-twitter"></i></a>
                                  <a class="btn btn-light btn-square mx-1" href="#"><i class="bi bi-facebook"></i></a>
                                  <a class="btn btn-light btn-square mx-1" href="#"><i class="bi bi-linkedin"></i></a>
                              </div>
                          </div>
                      </div>
                      <div class="bg-light text-center p-4">
                          <h5 class="text-uppercase">Full Name</h5>
                          <p class="m-0">Designation</p>
                      </div>
                  </div>
                  <div class="team-item">
                      <div class="position-relative overflow-hidden">
                          <img class="img-fluid w-100" src="img/team-2.jpg" alt="">
                          <div class="team-overlay">
                              <div class="d-flex align-items-center justify-content-start">
                                  <a class="btn btn-light btn-square mx-1" href="#"><i class="bi bi-twitter"></i></a>
                                  <a class="btn btn-light btn-square mx-1" href="#"><i class="bi bi-facebook"></i></a>
                                  <a class="btn btn-light btn-square mx-1" href="#"><i class="bi bi-linkedin"></i></a>
                              </div>
                          </div>
                      </div>
                      <div class="bg-light text-center p-4">
                          <h5 class="text-uppercase">Full Name</h5>
                          <p class="m-0">Designation</p>
                      </div>
                  </div>
                  <div class="team-item">
                      <div class="position-relative overflow-hidden">
                          <img class="img-fluid w-100" src="img/team-3.jpg" alt="">
                          <div class="team-overlay">
                              <div class="d-flex align-items-center justify-content-start">
                                  <a class="btn btn-light btn-square mx-1" href="#"><i class="bi bi-twitter"></i></a>
                                  <a class="btn btn-light btn-square mx-1" href="#"><i class="bi bi-facebook"></i></a>
                                  <a class="btn btn-light btn-square mx-1" href="#"><i class="bi bi-linkedin"></i></a>
                              </div>
                          </div>
                      </div>
                      <div class="bg-light text-center p-4">
                          <h5 class="text-uppercase">Full Name</h5>
                          <p class="m-0">Designation</p>
                      </div>
                  </div>
                  <div class="team-item">
                      <div class="position-relative overflow-hidden">
                          <img class="img-fluid w-100" src="img/team-4.jpg" alt="">
                          <div class="team-overlay">
                              <div class="d-flex align-items-center justify-content-start">
                                  <a class="btn btn-light btn-square mx-1" href="#"><i class="bi bi-twitter"></i></a>
                                  <a class="btn btn-light btn-square mx-1" href="#"><i class="bi bi-facebook"></i></a>
                                  <a class="btn btn-light btn-square mx-1" href="#"><i class="bi bi-linkedin"></i></a>
                              </div>
                          </div>
                      </div>
                      <div class="bg-light text-center p-4">
                          <h5 class="text-uppercase">Full Name</h5>
                          <p class="m-0">Designation</p>
                      </div>
                  </div>
                  <div class="team-item">
                      <div class="position-relative overflow-hidden">
                          <img class="img-fluid w-100" src="img/team-5.jpg" alt="">
                          <div class="team-overlay">
                              <div class="d-flex align-items-center justify-content-start">
                                  <a class="btn btn-light btn-square mx-1" href="#"><i class="bi bi-twitter"></i></a>
                                  <a class="btn btn-light btn-square mx-1" href="#"><i class="bi bi-facebook"></i></a>
                                  <a class="btn btn-light btn-square mx-1" href="#"><i class="bi bi-linkedin"></i></a>
                              </div>
                          </div>
                      </div>
                      <div class="bg-light text-center p-4">
                          <h5 class="text-uppercase">Full Name</h5>
                          <p class="m-0">Designation</p>
                      </div>
                  </div>
              </div>
          </div>
      </div> */}
      {/* Team End */}
      {/* Testimonial Start */}
      {/* <div class="container-fluid bg-testimonial py-5" style="margin: 45px 0;">
          <div class="container py-5">
              <div class="row justify-content-end">
                  <div class="col-lg-7">
                      <div class="owl-carousel testimonial-carousel bg-white p-5">
                          <div class="testimonial-item text-center">
                              <div class="position-relative mb-4">
                                  <img class="img-fluid mx-auto" src="img/testimonial-1.jpg" alt="">
                                  <div class="position-absolute top-100 start-50 translate-middle d-flex align-items-center justify-content-center bg-white" style="width: 45px; height: 45px;">
                                      <i class="bi bi-chat-square-quote text-primary"></i>
                                  </div>
                              </div>
                              <p>Dolores sed duo clita tempor justo dolor et stet lorem kasd labore dolore lorem ipsum. At lorem lorem magna ut et, nonumy et labore et tempor diam tempor erat. Erat dolor rebum sit ipsum.</p>
                              <hr class="w-25 mx-auto">
                              <h5 class="text-uppercase">Client Name</h5>
                              <span>Profession</span>
                          </div>
                          <div class="testimonial-item text-center">
                              <div class="position-relative mb-4">
                                  <img class="img-fluid mx-auto" src="img/testimonial-2.jpg" alt="">
                                  <div class="position-absolute top-100 start-50 translate-middle d-flex align-items-center justify-content-center bg-white" style="width: 45px; height: 45px;">
                                      <i class="bi bi-chat-square-quote text-primary"></i>
                                  </div>
                              </div>
                              <p>Dolores sed duo clita tempor justo dolor et stet lorem kasd labore dolore lorem ipsum. At lorem lorem magna ut et, nonumy et labore et tempor diam tempor erat. Erat dolor rebum sit ipsum.</p>
                              <hr class="w-25 mx-auto">
                              <h5 class="text-uppercase">Client Name</h5>
                              <span>Profession</span>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div> */}
      {/* Testimonial End */}
      {/* Blog Start */}
      {/* <div class="container-fluid py-5">
          <div class="container">
              <div class="border-start border-5 border-primary ps-5 mb-5" style="max-width: 600px;">
                  <h6 class="text-primary text-uppercase">Latest Blog</h6>
                  <h1 class="display-5 text-uppercase mb-0">Latest Articles From Our Blog Post</h1>
              </div>
              <div class="row g-5">
                  <div class="col-lg-6">
                      <div class="blog-item">
                          <div class="row g-0 bg-light overflow-hidden">
                              <div class="col-12 col-sm-5 h-100">
                                  <img class="img-fluid h-100" src="img/blog-1.jpg" style="object-fit: cover;">
                              </div>
                              <div class="col-12 col-sm-7 h-100 d-flex flex-column justify-content-center">
                                  <div class="p-4">
                                      <div class="d-flex mb-3">
                                          <small class="me-3"><i class="bi bi-bookmarks me-2"></i>Web Design</small>
                                          <small><i class="bi bi-calendar-date me-2"></i>01 Jan, 2045</small>
                                      </div>
                                      <h5 class="text-uppercase mb-3">Dolor sit magna rebum clita rebum dolor</h5>
                                      <p>Ipsum sed lorem amet dolor amet duo ipsum amet et dolore est stet tempor eos dolor</p>
                                      <a class="text-primary text-uppercase" href="">Read More<i class="bi bi-chevron-right"></i></a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-6">
                      <div class="blog-item">
                          <div class="row g-0 bg-light overflow-hidden">
                              <div class="col-12 col-sm-5 h-100">
                                  <img class="img-fluid h-100" src="img/blog-2.jpg" style="object-fit: cover;">
                              </div>
                              <div class="col-12 col-sm-7 h-100 d-flex flex-column justify-content-center">
                                  <div class="p-4">
                                      <div class="d-flex mb-3">
                                          <small class="me-3"><i class="bi bi-bookmarks me-2"></i>Web Design</small>
                                          <small><i class="bi bi-calendar-date me-2"></i>01 Jan, 2045</small>
                                      </div>
                                      <h5 class="text-uppercase mb-3">Dolor sit magna rebum clita rebum dolor</h5>
                                      <p>Ipsum sed lorem amet dolor amet duo ipsum amet et dolore est stet tempor eos dolor</p>
                                      <a class="text-primary text-uppercase" href="">Read More<i class="bi bi-chevron-right"></i></a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div> */}

      <a href="#" className="btn btn-primary py-3 fs-4 back-to-top">
        <i className="bi bi-arrow-up" />
      </a>
      {/* JavaScript Libraries */}
      {/* Template Javascript */}
    </div>
  );
};
export default Home;
