// Layout.js
import React from 'react';
import Footer from '../Component/Footer';


function Layout({ children }) {
  return (
    <div>
      {children}
      <Footer/>
    </div>
  );
}

export default Layout;
