import React from 'react';
import '../css/PrivacyPolicy.css'; // Ensure you create this CSS file for styles

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <h1>Privacy Policy</h1>
      {/* <p><strong>Effective Date:</strong> [Insert Date]</p> */}
      
      <p>Welcome to GREENMEDIBIOTECH. We value your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website.</p>

      <h2>1. Information We Collect</h2>
      <p>We may collect and process the following types of information:</p>
      <ul>
        <li><strong>Personal Identification Information:</strong> Name, email address, phone number, and other contact details.</li>
        <li><strong>Transaction Information:</strong> Details of purchases and payment methods.</li>
        <li><strong>Usage Data:</strong> Information about how you use our website, including IP address and browsing behavior.</li>
        <li><strong>Cookies:</strong> Small files placed on your device to enhance your experience on our website.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect for various purposes, including:</p>
      <ul>
        <li>To process and manage your orders and transactions.</li>
        <li>To communicate with you about your account, orders, and promotional offers.</li>
        <li>To improve our website and services based on your feedback and usage.</li>
        <li>To comply with legal obligations and enforce our terms and conditions.</li>
      </ul>

      <h2>3. Sharing Your Information</h2>
      <p>We do not sell or rent your personal information to third parties. We may share your information with:</p>
      <ul>
        <li><strong>Service Providers:</strong> Third parties who assist us in providing our services, such as payment processors and delivery companies.</li>
        <li><strong>Legal Authorities:</strong> When required by law or to protect our rights and interests.</li>
      </ul>

      <h2>4. Data Security</h2>
      <p>We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet or electronic storage is completely secure, so we cannot guarantee absolute security.</p>

      <h2>5. Your Rights</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Access, correct, or delete your personal information.</li>
        <li>Object to or restrict certain types of processing of your personal information.</li>
        <li>Withdraw consent where we rely on consent as the legal basis for processing.</li>
      </ul>
      <p>To exercise these rights, please contact us using the details provided below.</p>

      <h2>6. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and you are encouraged to review this policy periodically for any updates.</p>

      <h2>7. Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
      <ul>
        <li><strong>Email:</strong> greenmedibiotech1@gmail.com </li>
        <li><strong>Phone:</strong> 6238165384 </li>
        <li><strong>Address:</strong> Door No 13/2/4, ground floor, plamoottukada-uchakada road,venkulam, karode P.O </li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
