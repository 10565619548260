import React, { useEffect, useState } from "react";
import Product1 from "../img/product-1.png";
import { Carousel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../css/Selected.scss";
import AddressTopTab from "../Component/AddressTopTab";
import NavBar from "../Component/NavBar";
import { faMinus, faPlus } from "@fortawesome/fontawesome-free-solid";

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Noproduct from "../img/noproduct.png";

function ViewProducts() {
  const [count, setCount] = useState(1);
  const navigate = useNavigate();
  const { productId } = useParams();
  console.log(productId, "whatif");

  const [productsview, setProductsview] = useState([]);

  useEffect(() => {
    ViewProducts();
  }, [productId]);

  const ViewProducts = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5007/petshop/view-product",
        { product_id: productId }
      );
      setProductsview(response.data.productlist);
      console.log(response.data, "viewproductdata");
    } catch (error) {
      console.log(error);
    }
  };

  const Increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };
  const handleBuyNowClick = (product) => {
    navigate("/placeorder", { state: { product, quantity: count } });
  };

  return (
    <>
      <meta charSet="utf-8" />
      <title>PET SHOP - Pet Shop Website Template</title>
      <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      <meta content="Free HTML Templates" name="keywords" />
      <meta content="Free HTML Templates" name="description" />
      {/* Favicon */}
      <link href="img/favicon.ico" rel="icon" />
      {/* Google Web Fonts */}
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins&family=Roboto:wght@700&display=swap"
        rel="stylesheet"
      />
      {/* Icon Font Stylesheet */}
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css"
        rel="stylesheet"
      />
      <link href="lib/flaticon/font/flaticon.css" rel="stylesheet" />
      {/* Libraries Stylesheet */}
      <link
        href="lib/owlcarousel/assets/owl.carousel.min.css"
        rel="stylesheet"
      />
      {/* Customized Bootstrap Stylesheet */}
      <link href="css/bootstrap.min.css" rel="stylesheet" />
      {/* Template Stylesheet */}
      <link href="css/style.css" rel="stylesheet" />
      <div>
        <AddressTopTab />
      </div>
      <div>
        <NavBar />
      </div>
      <div className="container product-display">
        {productsview?.map((product, idx) => (
          <div className="selected-product">
            <div className="product-images">
              <Carousel>
                <div className="section-images">
                  <img
                    className="sec-image"
                    src={
                      product.p_image
                        ? `https://lunarsenterprises.com:5007/${product.p_image}`
                        : Noproduct // Use Noproduct as default image
                    }
                    alt={{}}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              </Carousel>
            </div>
            <div className="product-details">
              <h1>Eco Cleanser {product.p_name} </h1>
              <div className="selected-product-price">
                {/* <p className="selected-price">
                <span>Rs.</span>
                899
              </p> */}

                <div
                  className="selected-discounted-price"
                  style={{ color: "black" }}
                >
                  <span>Rs.</span>
                  {product.price}
                </div>

                {/* <div className="sale-container">sale</div> */}
              </div>
              {/* <h6>Shipping calculated at checkout.</h6> */}
              {/* <div className="color-selection">
              <h6>Color</h6>
              <div className="colors">
                <div
                  className="color-box"
                  onClick={{}}
                  style={{ cursor: "pointer" }}
                >
                 
                </div>
              </div>
            </div> */}
              <p style={{ marginBottom: "5px" }}>Quantity</p>
              <div className="counting-box">
                <button onClick={decrement}>
                  <FontAwesomeIcon icon={faMinus} />
                </button>
                <div className="count">{count}</div>
                <button onClick={Increment}>
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
              <div className="selected-product-details">
                <h6>Name: Eco cleanser {product.p_name}</h6>
                <h5>Description:</h5>
                <h6>{product.p_description}</h6>

                {/* <h6>No. of components: u8uuu</h6> */}
                {/* <h6>Pattern: nnnm</h6> */}
                <h6>Quantity:{count} </h6>
                {/* <p>uhnjnn</p> */}
              </div>
              <div className="add-buy-buttons">
                {/* <button style={{ backgroundColor: "transparent" }} onClick={{}}>
                Add to Cart
              </button> */}
                <button
                  style={{
                    backgroundColor: "#7AB730",
                    color: "white",
                    borderRadius: 10,
                    fontSize: 16,
                    fontFamily: "sans-serif",
                    fontWeight: 500,
                    border: "none",
                  }}
                  onClick={() => handleBuyNowClick(product)}
                >
                  Buy it now
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* <div className="recommended-products">
        <h4>You may also like</h4>
        <div className="product-display">
          <div className="product-card" style={{ cursor: "pointer" }}>
            <div className="product-image-container">
              <img src={Product1} alt="" className="default" />
              <img src={Product1} alt="" className="hover" />
              <div className="sale-container">200</div>
            </div>
            <div className="product-name">
              <h6>500</h6>
            </div>
            <div className="product-price-details">
              <del className="price">
                <span>Rs</span>
                100
              </del>
              <div className="discounted-price">
                <span>Rs</span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default ViewProducts;
