import React from "react";
import AddressTopTab from "../Component/AddressTopTab";
import NavBar from "../Component/NavBar";

import { Nav, Tab } from "react-bootstrap";
import Aboutimg from "../img/about.jpg"

function About() {
  return (
    <div>
      <meta charSet="utf-8" />
      <title>PET SHOP - Pet Shop Website Template</title>
      <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      <meta content="Free HTML Templates" name="keywords" />
      <meta content="Free HTML Templates" name="description" />
      {/* Favicon */}
      <link href="img/favicon.ico" rel="icon" />
      {/* Google Web Fonts */}
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins&family=Roboto:wght@700&display=swap"
        rel="stylesheet"
      />
      {/* Icon Font Stylesheet */}
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css"
        rel="stylesheet"
      />
      <link href="lib/flaticon/font/flaticon.css" rel="stylesheet" />
      {/* Libraries Stylesheet */}
      <link
        href="lib/owlcarousel/assets/owl.carousel.min.css"
        rel="stylesheet"
      />
      {/* Customized Bootstrap Stylesheet */}
      <link href="css/bootstrap.min.css" rel="stylesheet" />
      {/* Template Stylesheet */}
      <link href="css/style.css" rel="stylesheet" />
      {/* Topbar Start */}
      <AddressTopTab />
      {/* Topbar End */}
      {/* Navbar Start */}
      <NavBar />
      {/* Navbar End */}
      {/* About Start */}
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row gx-5">
            <div
              className="col-lg-5 mb-5 mb-lg-0"
              style={{ minHeight: "500px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded"
                  src={Aboutimg}
                  style={{ objectFit: "cover" }}

                />
              </div>
            </div>

            <Tab.Container defaultActiveKey="pills-1">
              <div className="col-lg-7">
                <div className="border-start border-5 border-primary ps-5 mb-5">
                  <h6 className="text-primary text-uppercase">About Us</h6>
                  <h1 className="display-5 text-uppercase mb-0">
                    We Keep Your Pets Happy All Time
                  </h1>
                </div>
                <h4 className="text-body mb-4">
                  We provide an essential services retailer which sells animals
                  and pet care resources to the public.
                </h4>
                <div className="bg-light p-4">
                  <Nav
                    className="nav nav-pills nav-fill justify-content-center mb-3"
                    style={{ width: "100%" }}
                  >
                    <Nav.Item>
                      <Nav.Link eventKey="pills-1">Our Mission</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="pills-2">Our Vision</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="pills-1">
                      <p>
                        Our mission is to provide our customers with a great
                        selection of high quality Pet Products and Pet Foods at
                        the best price possible. We invite you to shop and
                        compare our quality of products with any other pet
                        supply, grocery or mass market retailer.
                      </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="pills-2">
                      <p>
                        Our vision is to be the trusted destination that your
                        owners turn to for premium pet supplies, exceptional
                        customer service, and expert guidance, providing a
                        unique and enjoyable experience for your furry friends.
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </div>
      </div>
      {/* About End */}

    
      {/* Back to Top */}
      <a href="#" className="btn btn-primary py-3 fs-4 back-to-top">
        <i className="bi bi-arrow-up" />
      </a>
      {/* JavaScript Libraries */}
      {/* Template Javascript */}
    </div>
  );
}

export default About;
