import React from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import Logo from "../img/Logo.png";
function NavBar() {
  return (
    <Navbar
      bg="white"
      expand="lg"
      className="shadow-sm py-3 py-lg-0 px-3 px-lg-0"
    >
      <Container>
        <Navbar.Brand href="#home">
          <h1 className="m-0" style={{fontSize:"30px",fontFamily:'sans-serif',color:"black"}}>
            <img
              src={Logo}
              alt="Pet Shop Icon"
              className="img-fluid me-3"
              style={{ height: "100px", width: "140px", }}
            />
           GREENMEDIBIOTECH
          </h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarCollapse" />
        <Navbar.Collapse id="navbarCollapse">
          <Nav className="ms-auto py-0">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>
            <Nav.Link href="/product">Product</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link
              href="/Contact"
              className="nav-contact bg-primary text-white px-5 ms-lg-5"
            >
              Contact <i className="bi bi-arrow-right" />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
