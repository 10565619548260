import React from 'react';
import '../css/Termsandconditions.css'; // Ensure you create this CSS file for styles

const Termsandcondition = () => {
  return (
    <div className="container">
      <h1>Terms and Conditions</h1>
      {/* <p><strong>Effective Date:</strong> [Insert Date]</p> */}
      
      <p>Welcome to GREENMEDIBIOTECH. By accessing or using our website, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.</p>

      <h2>1. Introduction</h2>
      <p>These Terms and Conditions govern your use of our website and services. By using our website, you agree to these Terms and Conditions. If you do not agree with any part of these terms, you must not use our website.</p>

      <h2>2. Use of Our Website</h2>
      <ul>
        <li>You must be at least 18 years old to use our website.</li>
        <li>You agree to use our website only for lawful purposes and in a way that does not infringe the rights of others or restrict their use and enjoyment of the website.</li>
      </ul>

      <h2>3. Products and Services</h2>
      <ul>
        <li>All products and services offered on our website are subject to availability.</li>
        <li>We reserve the right to change or discontinue any product or service without prior notice.</li>
      </ul>

      <h2>4. Orders and Payments</h2>
      <ul>
        <li>All orders are subject to acceptance and availability.</li>
        <li>Prices and availability of products are subject to change without notice.</li>
        <li>Payment must be made in full at the time of purchase.</li>
      </ul>

      <h2>5. Shipping and Delivery</h2>
      <ul>
        <li>Shipping costs will be calculated based on the delivery location and selected shipping method.</li>
        <li>Delivery times are estimates and may vary based on factors beyond our control.</li>
      </ul>

      <h2>6. Returns and Refunds</h2>
      <ul>
        <li>We accept returns within 7 days of receipt, provided the items are unused and in their original packaging.</li>
        <li>Refunds will be processed to the original payment method within 7 days of receiving the returned item.</li>
      </ul>

      <h2>7. Privacy and Data Protection</h2>
      <p>We are committed to protecting your privacy. Please refer to our <a href="/privacy">Privacy Policy</a> for information on how we collect, use, and protect your personal data.</p>

      <h2>8. Intellectual Property</h2>
      <p>All content on our website, including text, graphics, logos, and images, is the property of [Your Pet Pharmacy Name] or its licensors and is protected by copyright and trademark laws.</p>

      <h2>9. Limitation of Liability</h2>
      <p>To the fullest extent permitted by law, GREENMEDIBIOTECH shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of our website or services.</p>

      <h2>10. Changes to Terms</h2>
      <p>We reserve the right to update these Terms and Conditions at any time. Changes will be posted on this page, and it is your responsibility to review these terms regularly.</p>

      <h2>11. Contact Us</h2>
      <p>If you have any questions or concerns about these Terms and Conditions, please contact us at:</p>
      <ul>
        <li><strong>Email:</strong> greenmedibiotech1@gmail.com </li>
        <li><strong>Phone:</strong> 6238165384 </li>
        <li><strong>Address:</strong> Door No 13/2/4, ground floor, plamoottukada-uchakada road,venkulam, karode P.O</li>
      </ul>
    </div>
  );
};

export default Termsandcondition;
