import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import Noproduct from "../img/noproduct.png";

function Productlisting() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5007/petshop/list-product"
      );
      setProducts(response.data.productlist);
      console.log(response.data.productlist, "productdata");
    } catch (error) {
      console.log(error);
    }
  };

  // Split products into chunks of 3
  const chunkedProducts = [];
  for (let i = 0; i < products?.length; i += 3) {
    chunkedProducts.push(products?.slice(i, i + 3));
  }

  return (
    <div className="container-fluid py-5">
      <div className="container">
        <div
          className="border-start border-5 border-primary ps-5 mb-5"
          style={{ maxWidth: "600px" }}
        >
          <h6 className="text-primary text-uppercase">Products</h6>
          <h1 className="display-5 text-uppercase mb-0">
            Products For Your Furry Friends
          </h1>
        </div>
        <Carousel
          prevIcon={
            <span className="carousel-control-prev-icon" aria-hidden="true" />
          }
          nextIcon={
            <span className="carousel-control-next-icon" aria-hidden="true" />
          }
        >
          {chunkedProducts?.map((productChunk, index) => (
            <Carousel.Item key={index}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {productChunk?.map((product, idx) => (
                  <div
                    key={idx}
                    style={{
                      width: "300px", // Increased width of product container
                      height: "300px", // Increased height of product container
                      margin: "10px",
                      padding: "10px",
                      boxSizing: "border-box",
                      // backgroundColor: "#f8f9fa",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Link
                      to={`/viewproduct/${product.p_id}`}
                      style={{
                        marginBottom: "10px",
                        textDecoration: "none",
                        color: "inherit",
                      }}
                    >
                      <img
                        style={{
                          width: "200px", // Increased width of product image
                          height: "200px", // Increased height of product image
                          objectFit: "cover",
                          marginBottom: "10px",
                          borderRadius: "10px",
                        }}
                        src={
                          product.p_image
                            ? `https://lunarsenterprises.com:5007/${product.p_image}`
                            : Noproduct // Use Noproduct as default image
                        }
                        alt={product.p_name}
                      />
                      <h6
                        style={{
                          textTransform: "uppercase",
                          marginBottom: "5px",
                        }}
                      >
                        {product.p_name}
                      </h6>
                      <h5 style={{ color: "#7AB730", marginBottom: "10px" }}>
                        Rs.{product.price}
                      </h5>
                    </Link>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {/* <a
                        style={{
                          display: "inline-block",
                          padding: "10px",
                          backgroundColor: "#7AB730",
                          color: "#fff",
                          borderRadius: "5px",
                          textDecoration: "none",
                          margin: "0 5px",
                        }}
                        href="#"
                      >
                        <i className="bi bi-cart" />
                      </a>
                      <a
                        style={{
                          display: "inline-block",
                          padding: "10px",
                          backgroundColor: "#7AB730",
                          color: "#fff",
                          borderRadius: "5px",
                          textDecoration: "none",
                          margin: "0 5px",
                        }}
                        href="#"
                      >
                        <i className="bi bi-eye" />
                      </a> */}
                    </div>
                  </div>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default Productlisting;
